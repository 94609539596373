.panelContainer {
  /* -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75); */
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: center;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}
